import { queryOptions, useQuery } from "@tanstack/vue-query";

export const useGetPlansQuery = () => {
  return useQuery({
    ...getPlanQuery(),
  });
};

export const getPlanQuery = () => {
  return queryOptions({
    queryKey: ["plans"],
    queryFn: async () => {
      const response = await useApi().payment.$get();

      const data = await response.json();
      return data;
    },
  });
};
